const DEFAULT_CAMPAIGN_ID = process.env.GATSBY_DEFAULT_CAMPAIGN_ID;

module.exports = {
    brandInfo: {
        phoneNumber: "18885364210",
        contactEmail: "info@carloanscanada.com",
        address: "10 Lower Spadina Ave, Toronto ON",
        copyright: `© ${new Date().getFullYear()} Car Loans Canada`,
        defaultCampaignId: DEFAULT_CAMPAIGN_ID || "21",
    },

    project: "clc-loan-application",
    name: "Car Loans Canada",
    shortName: `CLC`,
    title: `Car Loans Canada Application`,
    description: `Get the lowest rates on the best vehicles available!`,
    reportingDomain: "carloanscanada.com",

    siteUrl: "https://www.carloanscanada.com/application",
    homeUrl: "/",
    termsUrl: "/terms_of_use",
    privacyPolicyUrl: "/privacy_policy",
    successUrl: "/success",

    hideSuccessCtas: false,
    layout: {
        component: require.resolve("./src/layout/index.jsx"),
        theme: require.resolve("./themes/custom/single-page-theme.js"),
    },
    requiredPartialFields: [
        "firstName",
        "lastName",
    ],
};